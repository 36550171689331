import "core-js/stable";
import "regenerator-runtime/runtime";

import "./js/config.js";

// Load in vendor scripts (jquery loaded by Webpack directly);
import "bootstrap";

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';

// Load in styles
import "./scss/styles.scss";

// Conditional dynamic imports

// Webpack will store those modules in separate JS files and
// load them on demand, keeping payload down.


// Load in custom JS modules required on initial page load

if (document.querySelector('body.site-layout') != null) {
    import('./js/scrolling');
    import('./js/keyboard-accessibility-check');
}

if (document.querySelector('.header--menu') != null) {
    import('./js/main-navigation');
}

if (document.querySelector('#header--search-form') != null) {
    import('./js/header-search');
}

if (document.querySelector('#sticky-booking-widget') != null) {
    import('./modules/sticky-booking-widget/sticky-booking-widget.js');
}

if (document.querySelector('.emergency-message') != null) {
    import('./js/alert-message');
}

// iFLY fixer

const iflyNodeFixer = (nodes) => {
    // iterate through all nodes
    for (var i = 0; i < nodes.length; i++) { 
        var curNode = nodes[i];
        // It is a "TEXT_NODE"
        // i.E. <span>John</span>
        if (curNode.nodeType === Node.TEXT_NODE) {
            if(curNode.nodeValue.toLowerCase().indexOf('ifly') > -1) {
                let replacementNode = document.createElement('span');
                replacementNode.innerHTML = curNode.nodeValue.replace(/ifly/gi, '<span><span class="lower-i">i</span>FLY</span>');
                curNode.parentNode.insertBefore(replacementNode, curNode);
                curNode.parentNode.removeChild(curNode);
            }
        }
        // It is a "ELEMENT_NODE", meaning we need to go deeper
        else if (curNode.nodeType === Node.ELEMENT_NODE) {
            iflyNodeFixer(curNode.childNodes);
        }
    }
};
// retrives all childNodes of body
var childNodes = document.body.childNodes;
iflyNodeFixer(childNodes);

// Load in custom JS modules on initial page load and when DOM is updated

const addDataJsParsed = (elements, tag = '') => {
    elements.forEach(x => {
        x.setAttribute(`data-js-parsed--${tag}`,'');
    });
}

const jsModuleCheck = () => {

    let testQuery = null;

    // smart-bootstrap-tabs
    testQuery = document.querySelector('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
        addDataJsParsed(thisDomQuery, "smart-bootstrap-tabs");
        import('./js/smart-bootstrap-tabs').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hunt-watch
    testQuery = document.querySelector('.hunt-watch:not([data-js-parsed--hunt-watch])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hunt-watch:not([data-js-parsed--hunt-watch])');
        addDataJsParsed(thisDomQuery, "hunt-watch");
        import('./js/hunt-watch').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // non-lazyload img-load-watcher
    testQuery = document.querySelector('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
        addDataJsParsed(thisDomQuery, "img-load-watcher");
        import('./js/img-load-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // list-carousel
    testQuery = document.querySelector('.list-carousel:not([data-js-parsed--list-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.list-carousel:not([data-js-parsed--list-carousel])');
        addDataJsParsed(thisDomQuery, "list-carousel");
        import('./js/list-carousel').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - vimeo
    testQuery = document.querySelector('.hero--video.vimeo:not([data-js-parsed--vimeo])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.vimeo:not([data-js-parsed--vimeo])');
        addDataJsParsed(thisDomQuery, "vimeo");
        import('./js/hero-video-vimeo').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - youtube
    testQuery = document.querySelector('.hero--video.youtube:not([data-js-parsed--youtube])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.youtube:not([data-js-parsed--youtube])');
        addDataJsParsed(thisDomQuery, "youtube");
        import('./js/hero-video-youtube').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // height matching
    testQuery = document.querySelector('*[data-hm-group]:not([data-js-parsed--height-match])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-hm-group]:not([data-js-parsed--height-match])');
        addDataJsParsed(thisDomQuery, "height-match");
        import('./js/height-match').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // width-watcher
    testQuery = document.querySelector('*[data-width-watcher]:not([data-js-parsed--width-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-width-watcher]:not([data-js-parsed--width-watcher])');
        addDataJsParsed(thisDomQuery, "width-watcher");
        import('./js/width-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // lightboxes (PhotoSwipe v5 - galleries and single images)
    testQuery = document.querySelector('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
        addDataJsParsed(thisDomQuery, "lightbox");
        import('./modules/lightboxes-galleries/lightboxes.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // masonry-gallery
    testQuery = document.querySelector('.masonry-gallery:not([data-js-parsed--masonry-gallery])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.masonry-gallery:not([data-js-parsed--masonry-gallery])');
        addDataJsParsed(thisDomQuery, "masonry-gallery")
        import('./modules/masonry-gallery/masonry-gallery.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // data-sizes-js-fix (fixes lazysizes issue)
    testQuery = document.querySelector('*[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
        addDataJsParsed(thisDomQuery, "data-sizes-js-fix");
        import('./js/data-sizes-js-fix').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }
    
    // responsive nav bar
    testQuery = document.querySelector('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
        addDataJsParsed(thisDomQuery, "responsive-nav-bar");
        import('./modules/responsive-nav-bar/responsive-nav-bar.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // responsive table
    testQuery = document.querySelector('.responsive-table:not([data-js-parsed--responsive-table])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-table:not([data-js-parsed--responsive-table])');
        addDataJsParsed(thisDomQuery, "responsive-table");
        import('./modules/responsive-tables/responsive-tables.js');
    }

    // form number control
    testQuery = document.querySelector('.form-number-control:not(.readonly):not([data-js-parsed--form-number-control])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.form-number-control:not(.readonly):not([data-js-parsed--form-number-control])');
        addDataJsParsed(thisDomQuery, "form-number-control");
        import('./js/form-number-control').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // radio-checkbox-collapse-control
    testQuery = document.querySelector('.radio-checkbox-collapse-control:not([data-js-parsed--radio-checkbox-collapse-control])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.radio-checkbox-collapse-control:not([data-js-parsed--radio-checkbox-collapse-control])');
        addDataJsParsed(thisDomQuery, "radio-checkbox-collapse-control");
        import('./js/radio-checkbox-collapse-control').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // accordion-radio
    testQuery = document.querySelector('.accordion-radio:not([data-js-parsed--accordion-radio])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.accordion-radio:not([data-js-parsed--accordion-radio])');
        addDataJsParsed(thisDomQuery, "accordion-radio");
        import('./js/accordion-radio').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // accordion
    testQuery = document.querySelector('.accordion:not([data-js-parsed--accordion])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.accordion:not([data-js-parsed--accordion])');
        addDataJsParsed(thisDomQuery, "accordion");
        import('./js/accordion').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // scroll indicator
    testQuery = document.querySelector('.scroll-indicator:not([data-js-parsed--scroll-indicator])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.scroll-indicator:not([data-js-parsed--scroll-indicator])');
        addDataJsParsed(thisDomQuery, "scroll-indicator");
        import('./js/scroll-indicator').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // tripadvisor icon shiv
    if (document.querySelector('.fa-tripadvisor') != null) {
        import('./js/tripadvisor-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tripadvisor'));
        });
    }

    // tiktok icon shiv
    if (document.querySelector('.fa-tiktok') != null) {
        import('./js/tiktok-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tiktok'));
        });
    }

    // packages-listing
    testQuery = document.querySelector('.packages-listing:not(.readonly):not([data-js-parsed--packages-listing])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.packages-listing:not(.readonly):not([data-js-parsed--packages-listing])');
        addDataJsParsed(thisDomQuery, "packages-listing");
        import('./js/packages-listing').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // extras-listing
    testQuery = document.querySelector('.extras-listing:not(.readonly):not([data-js-parsed--extras-listing])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.extras-listing:not(.readonly):not([data-js-parsed--extras-listing])');
        addDataJsParsed(thisDomQuery, "extras-listing");
        import('./js/extras-listing').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    //select-date
    testQuery = document.querySelector('[data-js-select-date]:not([data-js-parsed--select-date])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('[data-js-select-date]:not([data-js-parsed--select-date])');
        addDataJsParsed(thisDomQuery, "select-date");
        import('./js/select-date').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    //customer-details
    testQuery = document.querySelector('#frmAddBooking:not(.readonly):not([data-js-parsed--frmAddBooking])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('#frmAddBooking:not(.readonly):not([data-js-parsed--frmAddBooking])');
        addDataJsParsed(thisDomQuery, "frmAddBooking");
        import('./js/customer-details').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    //discount-code
    testQuery = document.querySelector('#discountCodeContainer:not(.readonly):not([data-js-parsed--discountCodeContainer])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('#discountCodeContainer:not(.readonly):not([data-js-parsed--discountCodeContainer])');
        addDataJsParsed(thisDomQuery, "discountCodeContainer");
        import('./js/discount-code').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    //pay-buttons
    testQuery = document.querySelector('#paymentProviderButtons:not(.readonly):not([data-js-parsed--paymentProviderButtons])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('#paymentProviderButtons:not(.readonly):not([data-js-parsed--paymentProviderButtons]) [data-provider-name]');
        addDataJsParsed(thisDomQuery, "paymentProviderButtons");
        import('./js/pay-buttons').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

};

//// create a mutation observer to watch for dynamically added HTML

let checkRequested = false, checkTimeout = null;

// run a check on page load
jsModuleCheck();

// Select the node that will be observed for mutations
const targetNode = document.querySelector('main');
if(targetNode != null) {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = function(mutationsList) {
        // Use traditional 'for loops' for IE 11
        let checkNeeded = false;
        for(const mutation of mutationsList) {
            if (mutation.addedNodes && mutation.addedNodes.length > 0 && mutation.type === 'childList') {
                checkNeeded = true;
                iflyNodeFixer(mutation.addedNodes);
            }
        }
        if(checkNeeded && !checkRequested) {
            checkRequested = true;
            clearTimeout(checkTimeout);
            checkTimeout = setTimeout(() => {
                jsModuleCheck();
                checkRequested = false;
            }, 500);
        }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}